import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import sortBy from "lodash/sortBy";
import Layout from "../layout";
import Card from "../components/Card/Card";
import config from "../../data/SiteConfig";

export default class CategoryTemplate extends React.Component {
  render() {
    const {
      data: { contentfulCategory },
      location
    } = this.props;

    const posts = sortBy(contentfulCategory.post, "updatedAt").reverse();

    return (
      <Layout location={location}>
        <Helmet title={`${contentfulCategory.title} | ${config.siteTitle}`} />
        <section className="section">
          <div className="container">
            <h1 className="title is-size-3 has-text-centered">
              {contentfulCategory.title}
            </h1>
            <section className="section is-paddingless-horizontal">
              <div className="columns is-multiline is-centered">
                {posts.map(post => (
                  <div className="column is-4-desktop is-6" key={post.id}>
                    <Card
                      titleSize="is-size-5"
                      contentHeight={280}
                      slug={post.slug}
                      title={post.title}
                      description={post.description}
                      cover={post.cover}
                      category={contentfulCategory.title}
                      updatedAt={post.updatedAt}
                    />
                  </div>
                ))}
              </div>
            </section>
          </div>
        </section>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query($slug: String) {
    contentfulCategory(slug: { eq: $slug }) {
      title
      post {
        id
        slug
        title
        description
        cover {
          gatsbyImageData(
            width: 800
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        updatedAt
      }
    }
  }
`;
